import { defineNuxtRouteMiddleware, abortNavigation } from '#imports';
import { usePageContent } from '@/composables/usePageContent';

export default defineNuxtRouteMiddleware(async (to) => {
  if (import.meta.client) return;

  const { handlePagePreviewAuth } = usePageContent();
  try {
    await handlePagePreviewAuth(to);
  } catch (error) {
    console.error(error);

    return abortNavigation({
      statusCode: 404,
      message: 'Page not found',
    });
  }
});
